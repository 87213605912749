<template>
    <div class="animate__animated animate__fadeIn">
        <loading-circle v-if="loading"></loading-circle>
        <v-layout row class="mx-md-1" v-if="!loading">

            <v-col cols="12" lg="6">
                <v-card flat class="grey darken-3 fill-height">
                    <v-card-title>
                        <h3 v-html="$t('preferences.services.title')"></h3>
                    </v-card-title>

                    <v-card-subtitle v-html="$t('preferences.services.desc')"></v-card-subtitle>

                    <v-layout row class="mx-5 mb-5 dont-break-out">
                        <v-flex class="xs12 sm6 md4 lg3" v-for="service in sorted_services" :key="service.id">
                            <v-switch @change="object_changed(new_services, service.id)"
                                      :color="$variables.v.theme.secondary"
                                      :input-value="!new_services.includes(service.id)"
                                      :label="$t(service.text)"></v-switch>
                        </v-flex>
                    </v-layout>
                    <v-col>
                        <div class="apply-reset-button-container"
                             :key="unlock_services">
                            <v-btn :disabled="c_disabled_services_button || saved_services"
                                   depressed
                                   :color="$variables.v.theme.primary"
                                   class="mr-3"
                                   @click.once="reset_object(uservariables[0])">
                                {{$t('g.reset')}}
                            </v-btn>

                            <v-btn :disabled="c_disabled_services_button || saved_services"
                                   depressed
                                   :color="$variables.v.theme.primary"
                                   @click.once="apply_hidden_objects(new_services, uservariables[0], storagevariables[0], functions[0])">
                                {{$t('g.apply')}}
                            </v-btn>
                        </div>
                    </v-col>
                </v-card>
            </v-col>

            <v-col cols="12" lg="6">
                <v-card flat class="grey darken-3 fill-height">
                    <v-card-title>
                        <h3 v-html="$t('preferences.itemtypes.title')"></h3>
                    </v-card-title>

                    <v-card-subtitle v-html="$t('preferences.itemtypes.desc')"></v-card-subtitle>

                    <v-layout row class="mx-5 mb-5 dont-break-out">
                        <v-flex class="xs12 sm6 md4 lg3" v-for="itemtype in sorted_itemtypes" :key="itemtype.id">
                            <v-switch @change="object_changed(new_itemtypes, itemtype.id)"
                                      :color="$variables.v.theme.secondary"
                                      :input-value="!new_itemtypes.includes(itemtype.id)"
                                      :label="$t(itemtype.text)"></v-switch>
                        </v-flex>
                    </v-layout>
                    <v-col>
                        <div class="apply-reset-button-container"
                             :key="unlock_itemtypes">
                            <v-btn :disabled="c_disabled_itemtypes_button || saved_itemtypes"
                                   depressed
                                   :color="$variables.v.theme.primary"
                                   class="mr-3"
                                   @click.once="reset_object(uservariables[1])">
                                {{$t('g.reset')}}
                            </v-btn>

                            <v-btn :disabled="c_disabled_itemtypes_button || saved_itemtypes"
                                   depressed
                                   :color="$variables.v.theme.primary"
                                   @click.once="apply_hidden_objects(new_itemtypes, uservariables[1], storagevariables[1], functions[1])">
                                {{$t('g.apply')}}
                            </v-btn>
                        </div>
                    </v-col>
                </v-card>
            </v-col>


            <v-col cols="12" lg="6">
                <v-card flat class="grey darken-3 fill-height">
                    <v-card-title>
                        <h3 v-html="$t('preferences.notification.title')"></h3>
                    </v-card-title>

                    <v-card-subtitle v-html="$t('preferences.notification.desc')"></v-card-subtitle>

                    <v-card-subtitle :class="notifications_enabled ? 'success--text' : 'warning--text'"
                                     v-html="$t(notifications_enabled ? 'notification.granted' : 'notification.denied')"></v-card-subtitle>
                    <v-layout row class="mx-5 dont-break-out">
                        <v-flex class="xs12 sm6 md4 lg3">
                            <v-switch v-model="$store.notification.permissions.trending_giveaways"
                                      @change="enable_notification"
                                      :color="$variables.v.theme.secondary"
                                      :label="$t('nav-main.trending-giveaways')"></v-switch>
                        </v-flex>
                        <v-flex class="xs12 sm6 md4 lg3">
                            <v-switch v-model="$store.notification.permissions.all_giveaways"
                                      @change="enable_notification"
                                      :color="$variables.v.theme.secondary"
                                      :label="$t('nav-main.all-giveaways')"></v-switch>
                        </v-flex>
                        <v-flex class="xs12 sm6 md4 lg3">
                            <v-switch v-model="$store.notification.permissions.hot_deals"
                                      @change="enable_notification"
                                      :color="$variables.v.theme.secondary"
                                      :label="$t('nav-main.hot-deals')"></v-switch>
                        </v-flex>
                        <v-flex class="xs12 sm6 md4 lg3">
                            <v-switch v-model="$store.notification.permissions.all_deals"
                                      @change="enable_notification"
                                      :color="$variables.v.theme.secondary"
                                      :label="$t('nav-main.all-deals')"></v-switch>
                        </v-flex>
                    </v-layout>
                </v-card>
            </v-col>


            <v-col cols="12" lg="6">
                <v-card flat class="grey darken-3 fill-height">
                    <v-card-title>
                        <h3 v-html="$t('preferences.navigation.title')"></h3>
                    </v-card-title>

                    <v-card-subtitle v-html="$t('preferences.navigation.desc')"></v-card-subtitle>

                    <v-layout row class="mx-5 mb-5 dont-break-out">
                        <v-flex class="xs12 sm6 md4 lg3" v-for="nav in $variables.v.navigation_hideable" :key="nav.id">
                            <v-switch @change="object_changed(new_navigation, nav.id)"
                                      :color="$variables.v.theme.secondary"
                                      :input-value="!new_navigation.includes(nav.id)"
                                      :label="$t(nav.text)"></v-switch>
                        </v-flex>
                    </v-layout>
                    <v-col>
                        <div class="apply-reset-button-container"
                             :key="unlock_navigation">
                            <v-btn :disabled="c_disabled_navigation_button || saved_navigation"
                                   depressed
                                   :color="$variables.v.theme.primary"
                                   class="mr-3"
                                   @click.once="reset_object(uservariables[2])">
                                {{$t('g.reset')}}
                            </v-btn>

                            <v-btn :disabled="c_disabled_navigation_button || saved_navigation"
                                   depressed
                                   :color="$variables.v.theme.primary"
                                   @click.once="apply_hidden_objects(new_navigation, uservariables[2], storagevariables[2], functions[2])">
                                {{$t('g.apply')}}
                            </v-btn>
                        </div>
                    </v-col>
                </v-card>
            </v-col>


            <v-col cols="12" class="font-weight-bold text-center orange--text">{{$t('preferences.lastpass-warning')}}</v-col>
        </v-layout>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                sorted_services      : [], // List of all services
                sorted_itemtypes     : [], // List with all itemtypes
                new_services         : [], // Array with hidden services
                new_itemtypes        : [], // Array with hidden itemtypes
                new_navigation       : [], // Array with hidden navigation items
                uservariables        : [
                    "hidden_services",
                    "hidden_itemtypes",
                    "hidden_navigation"
                ],
                storagevariables     : [
                    "last_services_update",
                    "last_itemtypes_update",
                    "last_navigation_update"
                ],
                functions            : [
                    "update_hidden_services",
                    "update_hidden_itemtypes",
                    "update_hidden_navigation"
                ],
                saved_services       : false,
                saved_itemtypes      : false,
                saved_navigation     : false,
                notifications_enabled: false,
                unlock_services      : 0,
                unlock_itemtypes     : 100000,
                unlock_navigation    : 200000,
                delay                : 1000 * 60, /* ms * sec */
                loading              : false,
            };
        },
        computed: {
            c_disabled_services_button() {
                return (new Date - Date.parse(this.func.get_localstoragedata(this.storagevariables[0]))) < this.delay;
            },
            c_disabled_itemtypes_button() {
                return (new Date - Date.parse(this.func.get_localstoragedata(this.storagevariables[1]))) < this.delay;
            },
            c_disabled_navigation_button() {
                return (new Date - Date.parse(this.func.get_localstoragedata(this.storagevariables[2]))) < this.delay;
            },
        },
        created() {
            this.loading               = true;
            this.notifications_enabled = Notification.permission === "granted";

            this.new_services   = JSON.parse(JSON.stringify(this.$user.user.hidden_services));
            this.new_itemtypes  = JSON.parse(JSON.stringify(this.$user.user.hidden_itemtypes));
            this.new_navigation = JSON.parse(JSON.stringify(this.$user.user.hidden_navigation));

            this.sorted_itemtypes = this.$variables.v.itemtypes.sort(this.func.compare_by_pos);
            this.sorted_services  = this.$variables.v.services.sort(this.func.compare_by_pos);

            this.loading = false;
        },
        methods : {
            enable_notification() {
                // Let's check if the browser supports notifications
                if (!("Notification" in window))
                    alert("This browser does not support desktop notification");
                // Otherwise, we need to ask the user for permission
                else if (Notification.permission !== "granted")
                    Notification.requestPermission().then(() => {
                        this.notifications_enabled = Notification.permission === "granted";
                    });

                this.func.set_localstoragedata('notifications', JSON.stringify(this.$store.notification));
            },
            async apply_hidden_objects(array, uservariable, storage, func) {
                let userarray;
                // Array with hidden objects
                if (uservariable === this.uservariables[0])
                    userarray = this.$user.user.hidden_services;
                else if (uservariable === this.uservariables[1])
                    userarray = this.$user.user.hidden_itemtypes;
                else if (uservariable === this.uservariables[2])
                    userarray = this.$user.user.hidden_navigation;

                array.sort();
                if (!this.func.arrays_equal(userarray, array)) { // Only try to update if the arrays are not equal
                    this.func.set_localstoragedata(storage, Date());
                    array = array.filter((v) => v !== ''); // Clean empty values in Array

                    // Put the array into the user object
                    if (uservariable === this.uservariables[0])
                        this.$user.user.hidden_services = array;
                    else if (uservariable === this.uservariables[1])
                        this.$user.user.hidden_itemtypes = array;
                    else if (uservariable === this.uservariables[2])
                        this.$user.user.hidden_navigation = array;

                    // Update preferences depending on which button was pressed
                    let response = await this.func.ax("/php/update_user.php", {
                        func  : func,
                        userid: this.$user.user.id,
                        token : this.$user.user.token,
                        val   : array.join(";"),
                    });
                    await this.$root.check_response(response, () => {
                        // Toggle buttons and renable them after delay
                        if (uservariable === this.uservariables[0]) {
                            this.saved_services = true;
                            setTimeout(() => {
                                this.saved_services = false;
                            }, this.delay);
                        }
                        else if (uservariable === this.uservariables[1]) {
                            this.saved_itemtypes = true;
                            setTimeout(() => {
                                this.saved_itemtypes = false;
                            }, this.delay);
                        }
                        else if (uservariable === this.uservariables[2]) {
                            this.saved_navigation = true;
                            setTimeout(() => {
                                this.saved_navigation = false;
                            }, this.delay);
                        }

                        this.eventbus.$emit("SB", "preferences.msg.success", "success", 5);
                        this.func.set_localstoragedata("$user", JSON.stringify(this.$user.user));
                    }, "preferences.msg.error");
                }
                else
                    this.eventbus.$emit("SB", "preferences.msg.nothing-changed", "warning", 5);

                if (uservariable === this.uservariables[0])
                    this.unlock_services++;
                else if (uservariable === this.uservariables[1])
                    this.unlock_itemtypes++;
                else if (uservariable === this.uservariables[2])
                    this.unlock_navigation++;
            },
            object_changed(object, id) {
                if (object.includes(id))
                    object.splice(object.indexOf(id), 1);
                else
                    object.push(id)
            },
            reset_object(object) {
                // Reset the fields in the specific panel
                if (object === this.uservariables[0]) {
                    this.new_services = [];
                    this.apply_hidden_objects(this.new_services, 'hidden_services', 'last_services_update', 'update_hidden_services');
                }
                else if (object === this.uservariables[1]) {
                    this.new_itemtypes = [];
                    this.apply_hidden_objects(this.new_itemtypes, 'hidden_itemtypes', 'last_itemtypes_update', 'update_hidden_itemtypes');
                }
                else if (object === this.uservariables[2]) {
                    this.new_navigation = [];
                    this.apply_hidden_objects(this.new_navigation, 'hidden_navigation', 'last_navigation_update', 'update_hidden_navigation');
                }
            }
        },
        beforeRouteEnter(to, from, next) { // Prevent not signed in user from access this site
            next(vm => {
                if (vm.$user.user.auth === false)
                    next({path: vm.$variables.v.navigation.page404})
            })
        },
        metaInfo() {
            return {
                title: `${this.$t('meta.page-title-unformated')} - ${this.$t('meta.page-title-preferences')}`,
                meta : [{
                    name   : "description",
                    content: this.$t("meta.description"),
                }]
            }
        },
    }
</script>